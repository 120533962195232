<template>
  <v-tabs>
    <v-tab>{{ $t("settings.company.general") }}</v-tab>
    <v-tab v-if="canAccessFeature(FEATURES.PER_COMPANY_BRANDING)">{{
      $t("settings.company.branding")
    }}</v-tab>

    <v-tab-item>
      <div style="margin: 2rem">
        <v-progress-circular
          v-if="loading"
          :size="80"
          :width="8"
          color="accent"
          indeterminate
        ></v-progress-circular>
        <div v-else>
          <v-container
            v-for="(field, index) in companyFields"
            :key="index"
            style="margin: 0; padding: 0; width: 100%; max-width: 100%"
          >
            <company-setting-field :field="field" :parent="{}" />
          </v-container>

          <v-btn
            :disabled="loading"
            type="btn"
            @click="handleSubmit"
            color="primary"
            >{{ $t("common.save") }}
          </v-btn>
        </div>
      </div>
    </v-tab-item>

    <v-tab-item>
      <v-container
        style="margin: 0; padding: 0; width: 100%; max-width: 100%"
        v-if="!loading && canAccessFeature(FEATURES.PER_COMPANY_BRANDING)"
      >
        <company-branding-form />
      </v-container>
    </v-tab-item>
  </v-tabs>
</template>

<script>
import CompanySettingField from "./CompanySettingField.vue";
import CompanyBrandingForm from "./CompanyBrandingForm.vue";

import { mapActions, mapState } from "vuex";

export default {
  name: "CompanySettings",

  data() {
    return {
      loading: false,
    };
  },

  async created() {
    await this.getCompanySettings();
  },

  methods: {
    ...mapActions("configuration", [
      "getCompanySettings",
      "setCompanySettings",
    ]),

    updateData(value) {
      this.settings = value;
    },

    async handleSubmit() {
      this.loading = true;
      await this.setCompanySettings(this.companySettings);

      setTimeout(() => {
        this.loading = false;
      }, 1500);
    },
  },

  computed: {
    ...mapState("configuration", ["companySettings", "companyFields"]),
    ...mapState("users", ["currentUser"]),
  },

  components: {
    "company-setting-field": CompanySettingField,
    CompanyBrandingForm,
  },
};
</script>
