<template>
  <v-card flat class="pt-4">
    <v-card-title class="pl-0">{{ $t("company.whiteLabelLogo") }}</v-card-title>
    <v-card-text class="pl-0">
      <v-file-input :label="$t('company.whiteLabelLogo')" v-model="image" />

      <v-btn color="primary" @click="uploadImage">{{
        $t("common.upload")
      }}</v-btn>
    </v-card-text>

    <v-card-text class="pl-0" v-if="whiteLabelLogoForce !== undefined">
      <v-img
        max-width="700"
        :src="SO_API_BASE_URL + '/api/image/' + whiteLabelLogoForce"
      />
      <v-btn color="error" type="btn" @click="removeImage"
        >{{ $t("tag.removeImage") }}
      </v-btn>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapActions, mapState, mapGetters } from "vuex";

export default {
  name: "CompanyWhiteLabelUpload",
  data() {
    return {
      image: null,
    };
  },

  computed: {
    ...mapState("configuration", ["SO_API_BASE_URL"]),
    ...mapGetters("configuration", ["whiteLabelLogoForce"]),
  },

  methods: {
    ...mapActions("configuration", [
      "uploadWhiteLabelImage",
      "getCompanySettings",
    ]),

    removeImage() {
      console.error("Not implementet yet");
    },

    async uploadImage() {
      if (this.image === null) return;

      let formData = new FormData();
      formData.append("image", this.image);

      // Upload / replace the image for the company logotype
      await this.uploadWhiteLabelImage(formData);
      this.image = null;

      await this.getCompanySettings();
    },
  },
};
</script>
