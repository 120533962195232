<template>
  <v-container fluid>
    <v-row>
      <v-text-field
        v-model="title"
        :placeholder="$t('settings.company.brandingTitle')"
        prepend-icon="mdi-subtitles"
      />
    </v-row>

    <v-row>
      <v-btn color="primary" @click="submitBranding">{{
        $t("common.save")
      }}</v-btn>
    </v-row>

    <v-row>
      <company-white-label-upload />
    </v-row>
  </v-container>
</template>

<script>
import { mapState, mapActions } from "vuex";
import CompanyWhiteLabelUpload from "./CompanyWhiteLabelUpload";

export default {
  name: "CompnayBrandingForm",

  components: {
    CompanyWhiteLabelUpload,
  },

  data() {
    return {
      title: "",
    };
  },

  computed: {
    ...mapState("configuration", ["companySettings"]),
  },

  methods: {
    ...mapActions("configuration", ["setCompanySettings"]),

    async submitBranding() {
      const payload = {
        COMPANY_BRANDING_TITLE: this.title,
      };

      await this.setCompanySettings(payload);
    },
  },

  created() {
    this.title =
      this.companySettings["COMPANY_BRANDING_TITLE"] ?? "SensorOnline";
  },

  watch: {
    companySettings() {
      this.title =
        this.companySettings["COMPANY_BRANDING_TITLE"] ?? "SensorOnline";
    },
  },
};
</script>
